<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="550"
                  :options="optionsBarHealthUnits"
                  :series.sync="seriesBarHealthUnits"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Óbito por unidade de notificação</h4>
            <p class="category">
              Rejeitados de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
            <pagination
              class="pagination-no-border"
              v-model="pagination.health_unit.currentPage"
              :per-page="pagination.health_unit.perPage"
              :total="total.health_unit"
              @input="setPageHealthUnit"
            />
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar-sex-fax-default">
                <apexchart
                  type="bar"
                  height="550"
                  :options="optionsSexFaxDefault"
                  :series="serieSexFaxDefault"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">
              Pirâmide etária de óbitos faixa etária padrão
            </h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarCid"
                  :series="seriesBarCid"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.cid.currentPage"
              :per-page="pagination.cid.perPage"
              :total="total.cid"
              @input="setPageCid"
            />
            <h4 class="title">
              Classificação de AIH por diagnostico principal
            </h4>
            <p class="category">
              Principais CIDs de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih-value">
                <apexchart
                  type="line"
                  height="380"
                  :options="optionsLinesCid"
                  :series="seriesLinesCid"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Incidencia de CID por mês de competência</h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ cids[0] }} em {{ dataSet.year }} {{ region.name }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="380"
                  :options="optionsBarCidDnc"
                  :series="seriesBarCidDnc"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <pagination
              class="pagination-no-border"
              v-model="pagination.cidDnc.currentPage"
              :per-page="pagination.cidDnc.perPage"
              :total="total.cidDnc"
              @input="setPageCidDnc"
            />
            <h4 class="title">
              Classificação de AIH por diagnostico principal
            </h4>
            <p class="category">
              Principais CIDs de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih-value">
                <apexchart
                  type="line"
                  height="380"
                  :options="optionsLinesCidDnc"
                  :series="seriesLinesCidDnc"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Incidencia de CID por mês de competência</h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ cidsDnc[0] }} em {{ dataSet.year }} {{ region.name }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar-sex-fax-details">
                <apexchart
                  type="bar"
                  height="225"
                  :options="optionsSexFaxInf1"
                  :series="serieSexFaxInf1"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">
              Pirâmide etária de óbitos faixa etária Infantil
            </h4>
            <p class="category">
              Cid de maior ocorrência
              <span class="text-success">
                {{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart">
                <apexchart
                  type="bar"
                  height="225"
                  :options="optionsBarCivil"
                  :series.sync="seriesBarCivil"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">
              Classificação por estado civil de maiores de 18 anos
            </h4>
            <p class="category">
              Rejeitados de,
              <span class="text-success"
                >{{ dataSet.year }} {{ region.name }}</span
              >
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { Pagination } from "@/components";

export default {
  components: {
    apexchart: VueApexCharts,
    Pagination
  },
  data() {
    return {
      region: { name: "" },
      source: this.$route.params.source,
      type: this.$route.params.type,
      id: this.$route.params.id,
      location_type: this.$route.params.location_type
        ? this.$route.params.location_type
        : null,
      location_id: this.$route.params.location_id
        ? this.$route.params.location_id
        : null,
      scope_location: this.$route.params.scope_location
        ? this.$route.params.scope_location
        : null,
      dataSet: { year: null, alias: null },
      filter_cids: this.$store.state.sim.filter_cids,
      filter_chapters_cids: this.$store.state.sim.filter_chapters_cids,
      filter_groups_cids: this.$store.state.sim.filter_groups_cids,
      seriesBarHealthUnits: [],
      cids: [],
      optionsBarHealthUnits: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "80%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"]
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de óbitos por Unidades de Notificação",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Quantidade de óbitos por CNES",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      table: {
        health_unit: []
      },
      serieSexFaxDefault: [],
      optionsSexFaxDefault: {
        chart: {
          type: "bar",
          height: 440,
          stacked: true
        },
        colors: ["#008FFB", "#FF4560"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%"
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "12px"
          },
          formatter: (val, opt) => {
            return (
              Math.abs(val) +
              "% : " +
              this.serieSexFaxDefault[opt.seriesIndex].absolute[
                opt.dataPointIndex
              ]
            );
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: -20,
          max: 20,
          title: {
            // text: 'Age',
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function(val) {
              return val;
            }
          },
          y: {
            formatter: (val, opt) => {
              return (
                Math.abs(val) +
                "% : " +
                this.serieSexFaxDefault[opt.seriesIndex].absolute[
                  opt.dataPointIndex
                ]
              );
            }
          }
        },
        title: {
          text: "Pirâmide etária de óbitos"
        },
        xaxis: {
          categories: [],
          title: {
            text: "Porcentagem %"
          },
          labels: {
            formatter: function(val) {
              return Math.abs(Math.round(val)) + "%";
            }
          }
        }
      },
      serieSexFaxInf1: [],
      optionsSexFaxInf1: {
        chart: {
          type: "bar",
          height: 225,
          stacked: true
        },
        colors: ["#008FFB", "#FF4560"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%"
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "12px"
          },
          formatter: (val, opt) => {
            return (
              Math.abs(val) +
              "% : " +
              this.serieSexFaxDefault[opt.seriesIndex].absolute[
                opt.dataPointIndex
              ]
            );
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: -35,
          max: 35,
          title: {
            // text: 'Age',
          }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function(val) {
              return val;
            }
          },
          y: {
            formatter: (val, opt) => {
              return (
                Math.abs(val) +
                "% : " +
                this.serieSexFaxDefault[opt.seriesIndex].absolute[
                  opt.dataPointIndex
                ]
              );
            }
          }
        },
        title: {
          text: "Pirâmide etária de óbitos faixa etária Infantil "
        },
        xaxis: {
          categories: [],
          title: {
            text: "Porcentagem %"
          },
          labels: {
            formatter: function(val) {
              return Math.abs(Math.round(val)) + "%";
            }
          }
        }
      },
      seriesBarCivil: [],
      optionsBarCivil: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "80%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"]
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação por estado civil",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Maiores de 18 anos",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      seriesLinesCid: [],
      optionsLinesCid: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição CIDs por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesBarCid: [],
      optionsBarCid: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de Óbitos por CID",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência de CID",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      cidsDnc: [],
      seriesLinesCidDnc: [],
      optionsLinesCidDnc: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição CIDs DNC por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesBarCidDnc: [],
      optionsBarCidDnc: {
        chart: {
          type: "bar",
          height: 380
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
              orientation: "horizontal"
            }
          }
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7"
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#252525"],
            fontSize: "10px"
          },
          formatter: function(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
            blur: 0.5,
            opacity: 0.5
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          show: false
        },
        title: {
          text: "Classificação de óbitos por CIDs de DNC",
          align: "center",
          floating: true
        },
        subtitle: {
          text: "Ordenado pela incidência de CID",
          align: "center"
        },
        tooltip: {
          theme: "light",
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function() {
                return "";
              }
            }
          }
        }
      },
      total: {
        health_unit: 0,
        cid: 0,
        cidDnc: 0
      },
      pagination: {
        health_unit: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        cid: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        },
        cidDnc: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [10, 15, 20, 25, 50]
        }
      }
    };
  },
  computed: {
    sortHealthUnit() {
      if (this.sortation.health_unit.order === "desc") {
        return `-${this.sortation.health_unit.field}`;
      }

      return this.sortation.health_unit.field;
    },

    fromHealthUnit() {
      return (
        this.pagination.health_unit.perPage *
        (this.pagination.health_unit.currentPage - 1)
      );
    },

    toHealthUnit() {
      let highBound = this.fromHealthUnit + this.pagination.health_unit.perPage;
      if (this.total.health_unit < highBound) {
        highBound = this.total.health_unit;
      }
      return highBound;
    }
  },
  async create() {},
  async mounted() {
    this.welcomeMessage();
    await this.getSerieDeathByHealthUnit();
    this.getSerieSexFaxDefault();
    this.getSerieSexFaxInf1();
    this.getSeriesBarCivil();
    this.getSerieCids();
    this.getSerieCidsDnc();

    if (this.location_type != null) {
      this.getRegion();
    }
  },
  props: {},
  methods: {
    setFilters(params) {
      if (this.location_type == null) {
        params = this.setFiltersCids(params);
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;
        params.scope_location = this.scope_location;

        params = this.setFiltersCids(params);
      }
      return params;
    },
    setFiltersCids(params) {
      if (this.filter_cids != null) {
        params.column_filters = "causabas";
        params.term_filters = this.filter_cids;
      }

      if (this.filter_chapters_cids != null) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.filter_chapters_cids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push(chapterCid);
        }
      }

      if (this.filter_groups_cids != null) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.filter_groups_cids) {
          params.column_filter_or.push("causabas");
          params.term_filter_or_range.push(groupCid);
        }
      }

      return params;
    },
    async welcomeMessage(name = "") {
      await this.getDataSet();
      this.$emit("welcomeMessage", {
        title: `Dados de SIM do ano de ${this.dataSet.year} ${name}`
      });
    },
    async getDataSet() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
    },
    async getRegion() {
      let result;
      if (this.location_type == "city") {
        result = await Vue.$http.get(`v1/city_show/${this.location_id}/`);
      } else if (this.location_type == "microregion") {
        result = await Vue.$http.get(
          `v1/microregion_sesapi_show/${this.location_id}/`
        );
      } else if (this.location_type == "mesoregion") {
        result = await Vue.$http.get(
          `v1/mesoregion_sesapi_show/${this.location_id}/`
        );
      }
      this.region = result.data;
      this.welcomeMessage(this.region.name);
    },
    getClass: () => {
      return "md-primary";
    },
    async onSelect(item) {
      this.health_unit_select = item.code;
      // this.getTableDeathByHealthUnit();
    },
    setPageHealthUnit(page) {
      this.pagination.health_unit.currentPage = page;
      this.getSerieDeathByHealthUnit();
    },
    async getSerieDeathByHealthUnit() {
      let result;

      let params = {
        per: "codestab",
        rating: "contador",
        operation: "count",
        per_page: this.pagination.health_unit.perPage,
        page: this.pagination.health_unit.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarHealthUnits.xaxis.categories.length > 0) {
        this.optionsBarHealthUnits.xaxis.categories.pop();
      }

      while (this.seriesBarHealthUnits.length > 0) {
        this.seriesBarHealthUnits.pop();
      }

      result.data.data.forEach(async itemData => {
        this.optionsBarHealthUnits.xaxis.categories.push(
          itemData.alias_company_name
        );
        data.push(itemData.count);
      });

      this.seriesBarHealthUnits.push({
        name: "causabas",
        data: data
      });

      this.total.health_unit = result.data.total;
    },
    async getSerieSexFaxDefault() {
      let direction = {
        left: -1,
        right: 1
      };

      let sex = [
        {
          name: "Masculino",
          value: "1",
          direction: direction.left,
          sum: 0,
          data: [],
          percent: [],
          categories: []
        },
        {
          name: "Feminino",
          value: "2",
          direction: direction.right,
          sum: 0,
          data: [],
          percent: [],
          categories: []
        }
      ];
      let result;
      let total = 0;

      let params = {
        per: "idade",
        rating: "contador",
        ranger: "faixa_etaria_pd",
        per_page: 10,
        page: 1
      };
      params = this.setFilters(params);
      let data;
      for (var i = 0; i < sex.length; i++) {
        params.column_filter = ["sexo"];
        params.term_filter = [sex[i].value];

        result = await Vue.$http.get(
          `v1/dataset/consume/serie/range/datasus/sim/${this.id}/`,
          {
            params: params
          }
        );
        data = await result.data.data;

        for (let prop in data[0]) {
          sex[i].categories.push(prop);
          await sex[i].data.push(data[0][prop]);
          sex[i].sum += await data[0][prop];
        }

        total += sex[i].sum;
      }
      for (const category of sex[1].categories) {
        this.optionsSexFaxDefault.xaxis.categories.push(category);
      }

      let one_percent = total / 100;

      for (let i = 0; i < sex.length; i++) {
        for (let j = 0; j < sex[i].data.length; j++) {
          sex[i].percent.push(
            (sex[i].data[j] / one_percent).toFixed(2) * sex[i].direction
          );
        }
        this.serieSexFaxDefault.push({
          name: sex[i].name,
          data: sex[i].percent,
          absolute: sex[i].data
        });
      }
    },
    async getSerieSexFaxInf1() {
      let direction = {
        left: -1,
        right: 1
      };

      let sex = [
        {
          name: "Masculino",
          value: "1",
          direction: direction.left,
          sum: 0,
          data: [],
          percent: [],
          categories: []
        },
        {
          name: "Feminino",
          value: "2",
          direction: direction.right,
          sum: 0,
          data: [],
          percent: [],
          categories: []
        }
      ];
      let result;
      let total = 0;

      let params = {
        per: "idade",
        rating: "contador",
        ranger: "faixa_etaria_inf1",
        per_page: 10,
        page: 1
      };
      params = this.setFilters(params);
      let data;
      for (var i = 0; i < sex.length; i++) {
        params.column_filter = ["sexo"];
        params.term_filter = [sex[i].value];

        result = await Vue.$http.get(
          `v1/dataset/consume/serie/range/datasus/sim_inf/${this.id}/`,
          {
            params: params
          }
        );
        data = await result.data.data;

        for (let prop in data[0]) {
          sex[i].categories.push(prop);
          await sex[i].data.push(data[0][prop]);
          sex[i].sum += await data[0][prop];
        }

        total += sex[i].sum;
      }
      for (const category of sex[1].categories) {
        this.optionsSexFaxInf1.xaxis.categories.push(category);
      }

      let one_percent = total / 100;

      for (let i = 0; i < sex.length; i++) {
        for (let j = 0; j < sex[i].data.length; j++) {
          sex[i].percent.push(
            (sex[i].data[j] / one_percent).toFixed(2) * sex[i].direction
          );
        }
        this.serieSexFaxInf1.push({
          name: sex[i].name,
          data: sex[i].percent,
          absolute: sex[i].data
        });
      }
    },
    async getSeriesBarCivil() {
      let result;

      let params = {
        per: "estciv",
        rating: "contador",
        operation: "count",
        column_filter: ["idade", "estciv"],
        term_filter: ["418", "9"],
        operator_filter: [">=", "!="],
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];
      result.data.data.forEach(async itemData => {
        let category;
        switch (itemData.estciv) {
          case "1":
            category = "Solteiro";
            break;
          case "2":
            category = "Casado";
            break;
          case "3":
            category = "Viúvo";
            break;
          case "4":
            category = "Separado Jud.";
            break;
          case "5":
            category = "U. Estável";
            break;
        }
        this.optionsBarCivil.xaxis.categories.push(category);
        data.push(itemData.count);
      });
      this.seriesBarCivil.push({
        name: `barCivil`,
        data: data
      });
    },
    async getSerieCids() {
      let result;

      let params = {
        per: "causabas",
        rating: "contador",
        operation: "count",
        per_page: this.pagination.cid.perPage,
        page: this.pagination.cid.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarCid.xaxis.categories.length > 0) {
        this.optionsBarCid.xaxis.categories.pop();
      }

      while (this.seriesLinesCid.length > 0) {
        this.seriesLinesCid.pop();
      }

      while (this.seriesBarCid.length > 0) {
        this.seriesBarCid.pop();
      }

      result.data.data.forEach(async itemData => {
        this.cids.push(itemData.causabas);
        this.getSerieCid(itemData.causabas);
        this.optionsBarCid.xaxis.categories.push(itemData.description);
        data.push(itemData.count);
      });

      this.seriesBarCid.push({
        name: "quant",
        data: data
      });

      this.total.cid = result.data.total;
    },
    async getSerieCid(code) {
      let result;

      let params = {
        per: "dtobito",
        rating: "causabas",
        operation: "count",
        column_filter: ["causabas"],
        term_filter: [code],
        per_page: 12,
        page: 1
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];
      result.data.data.forEach(async itemData => {
        data.push(itemData.count);
      });
      this.seriesLinesCid.push({
        name: code,
        data: data
      });
    },
    async getSerieCidsDnc() {
      let result;

      let params = {
        per: "causabas",
        rating: "contador",
        operation: "count",
        dnc: true,
        per_page: this.pagination.cidDnc.perPage,
        page: this.pagination.cidDnc.currentPage
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];

      while (this.optionsBarCidDnc.xaxis.categories.length > 0) {
        this.optionsBarCidDnc.xaxis.categories.pop();
      }

      while (this.seriesLinesCidDnc.length > 0) {
        this.seriesLinesCidDnc.pop();
      }

      while (this.seriesBarCidDnc.length > 0) {
        this.seriesBarCidDnc.pop();
      }

      result.data.data.forEach(async itemData => {
        this.cidsDnc.push(itemData.causabas);
        this.getSerieCidDnc(itemData.causabas);
        this.optionsBarCidDnc.xaxis.categories.push(itemData.description);
        data.push(itemData.count);
      });

      this.seriesBarCidDnc.push({
        name: "quant",
        data: data
      });

      this.total.cidDnc = result.data.total;
    },
    async getSerieCidDnc(code) {
      let result;

      let params = {
        per: "dtobito",
        rating: "causabas",
        operation: "count",
        column_filter: ["causabas"],
        term_filter: [code],
        per_page: 12,
        page: 1
      };
      params = this.setFilters(params);

      result = await Vue.$http.get(
        `v1/dataset/consume/serie/datasus/sim/${this.id}/`,
        {
          params: params
        }
      );

      let data = [];
      result.data.data.forEach(async itemData => {
        data.push(itemData.count);
      });
      this.seriesLinesCidDnc.push({
        name: code,
        data: data
      });
    },
    setPageCid(page) {
      this.pagination.cid.currentPage = page;
      this.getSerieCids();
    },
    setPageCidDnc(page) {
      this.pagination.cidDnc.currentPage = page;
      this.getSerieCidsDnc();
    }
  }
};
</script>
